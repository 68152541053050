$footer-break-point-max: xs;
$footer-break-point-min: sm;

.Footer
{
  flex-shrink: 0;
  background-color: $gray-10;
  color: $gray-9;
  text-align: center;
  min-height: 285px;

  &-container.l-container
  {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include bplte(xs)
    {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  &-top
  {
    display: flex;
    flex-direction: column;
    padding: 36px 0;
    border-bottom: $border-gray;
    flex-grow: 1;

    @include bpgte(sm)
    {
      padding: 50px 0;
      flex-direction: row;
      justify-content: space-between;

      &-first
      {
        display: flex;
      }
    }

    &-second
    {
      width: 262px;
      height: 92px;

      @include bplte(xs)
      {
        order: -1;
      }
    }
  }

  &-links
  {
    display: flex;
    flex-direction: column;
    align-items: start;

    &-title
    {
      @include font-sizes(18px, 22px);
      font-weight: $font-weight-bold;
      margin-bottom: 13px;
    }

    &-content
    {
      @include bpgte(sm)
      {
        display: flex;
        flex-direction: column;
      }

      @include bpgte(md)
      {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &-subCat
    {
      display: flex;
      flex-direction: column;
      align-items: start;

      @include bpgte(md)
      {
        &:first-child
        {
          margin-right: 50px;
        }
      }
    }
  }

  &-link
  {
    color: $gray-2;
  }

  &-socials
  {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: $dark-blue;
    align-items: start;
    min-width: 200px;

    @include bplte(xs)
    {
      margin-top: 36px;
      margin-bottom: 36px;
    }
  }

  &-socials-title
  {
    margin-bottom: 11px;
    font-weight: $font-weight-bold;
  }

  &-socialLogo
  {
    width: 40px;
    height: 40px;
  }

  &-socialLink
  {
    display: inline;

    &:not(:first-child)
    {
      margin-left: 17px;
    }
  }

  &-bottom
  {
    padding: 36px 0;
    font-size: 11px;

    &-first,
    &-second
    {
      display: flex;
      flex-direction: column;
      align-items: start;

      @include bpgte(sm)
      {
        flex-direction: row;
      }
    }

    &-first
    {
      white-space: pre-wrap;

      @include bplte(xs)
      {
        margin-bottom: 30px;
      }
    }

    &-part::after
    {
      content: '|';
      margin: 0 3px;
    }

    @include bpgte(sm)
    {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
    }
  }

  &-entrustLink
  {
    text-decoration: underline;

    &:hover
    {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
