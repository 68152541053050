/** @define Link */

@mixin Link
{
  @include font-sizes(16px, 24px);
  font-family: $font-family-secondary;
  color: $text-primary;
  font-weight: $font-weight-normal;
  text-decoration: underline;
  text-transform: uppercase;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    color: $text-primary;
    text-decoration: none;
    cursor: pointer;
  }

  /* postcss-bem-linter: ignore */
  &:visited,
  &:active,
  &:focus
  {
    color: $text-primary;
    text-decoration: underline;
  }
}

@mixin Link--body
{
  @include font-sizes(14px, 24px);
  color: $brand-blue;
  text-decoration: underline;
  font-weight: $font-weight-normal;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    color: $brand-blue;
    text-decoration: none;
    cursor: pointer;
  }

  /* postcss-bem-linter: ignore */
  &:visited,
  &:active,
  &:focus
  {
    color: $brand-blue;
    text-decoration: underline;
  }
}

@mixin Link--header
{
  @include font-sizes(20px, 25px);
  font-family: $font-family-secondary;
  color: white;
  font-weight: $font-weight-normal;
  text-decoration: none;
  padding: 10px 15px;
  border-bottom: 5px solid transparent;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    color: $brand-orange;
    text-decoration: none;
    cursor: pointer;
  }

  /* postcss-bem-linter: ignore */
  &:active
  {
    border-color: $brand-orange;
  }

  /* postcss-bem-linter: ignore */
  &:visited,
  &:active,
  &:focus
  {
    color: $text-primary;
    text-decoration: none;
  }
}

@mixin Link--footer
{
  @include Link--body;

  @include fa-icon-after($fa-var-angle-right)
  {
    margin-left: 5px;
  }

  text-decoration: none;
  color: $gray-9;

  /* postcss-bem-linter: ignore */
  &:hover,
  &:visited,
  &:active,
  &:focus
  {
    color: $gray-9;
  }
}

.Link
{
  @include Link;

  &--body
  {
    @include Link--body;
  }

  &--header
  {
    @include Link--header;
  }

  &--breadcrumb
  {
    // same as body link
    @include Link--body;
  }

  &--footer
  {
    @include Link--footer;
  }
}
