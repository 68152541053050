.ObjectFit
{
  &-container
  {
    // act like an image by default
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }

  &--active
  {
    opacity: 0;
  }
}
