/** @define Directory */

// Directory Ace
.Directory
{
  &--ace
  {
    @include l-container;

    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  &--ace &-title
  {
    width: 100%;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    margin-top: $cobalt-base-padding;
  }

  &--ace &-content
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-listTeaser
  {
    @include bplte(xs)
    {
      width: 100%;
      margin-left: $cobalt-base-padding/2;
      margin-right: $cobalt-base-padding/2;

      &:not(:last-child)
      {
        border-bottom: $border-default;
        padding-bottom: $cobalt-base-padding;
        margin-bottom: $cobalt-base-padding;
      }
    }

    @include bpgte(sm)
    {
      width: 50%;
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
      padding-bottom: $cobalt-base-padding;
    }

    @include bpgte(md)
    {
      width: 25%;
    }
  }

  &--ace &-listLinks
  {
    @include bp(sm)
    {
      column-count: 2;
    }

    @include bp(md)
    {
      column-count: 3;
    }

    @include bpgte(lg)
    {
      column-count: 4;
    }
  }

  &--ace &-listItem
  {
    text-transform: uppercase;
  }

  &--ace &-listLink
  {
    display: inline-block;
    margin-bottom: $cobalt-base-padding/2;

    &::after
    {
      content: attr(data-count);
      margin-left: 1ch;
    }
  }
}
