/** @define Teaser */

.Teaser
{
  background-color: $brand-white;
  padding: $padded-padding;
  border: $border-default;
  height: 100%;

  &--nearby
  {
    @include bplte(xs)
    {
      width: 80vw;
    }
  }

  &--nearby &-title,
  &--nearby &-open,
  &--nearby &-address
  {
    @include bplte(xs)
    {
      white-space: normal;
    }
  }

  &--locator
  {
    @include bpgte(md)
    {
      padding-left: 58px;
      border: 0;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 2px transparent;
      position: relative;

      &::before,
      &::after
      {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: $border-blank;
      }

      &::before
      {
        top: 0;
        left: 0;
      }

      &::after
      {
        top: 0;
        left: 0;
      }
    }

    @include bplte(sm)
    {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }

  &--locator &-linksRow
  {
    display: block;
    margin-top: $spaced-margin;
  }

  &--locator &-links
  {
    display: none;
  }

  &-title,
  &-open
  {
    margin-bottom: $padded-padding/2;
  }

  /* postcss-bem-linter: ignore */
  .c-AddressRow:not(:first-child)
  {
    display: none;
  }

  /* postcss-bem-linter: ignore */
  .c-location-hours-today-day-hours
  {
    font-weight: $font-weight-bold;
  }

  &-linksRow
  {
    display: none;
  }

  &-titleLink
  {
    @include Link;
  }

  &-titleLink,
  &-titleSpan
  {
    @include font-sizes(20px, 24px);
    text-transform: none;
  }

  &-services,
  &-phone
  {
    display: none;
  }
}
