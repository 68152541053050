/** @define Main */

.Main
{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 1px; //IE11 fix

  @include bpgte(sm)
  {
    flex-wrap: wrap;
  }

  &-breadcrumbs
  {
    padding-top: $padded-padding;

    @include bplte(xs)
    {
      border-top: $border-default;
      order: 1;
    }

    a
    {
      @include Link--body;
    }
  }

  &-content
  {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    min-height: 1px; //IE11 fix
  }
}
