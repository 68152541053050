// common theming goes here, e.g. global component styling

.c-bread-crumbs-list
{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Breadcrumbs-separator,
.c-bread-crumbs-item
{
  padding-bottom: $padded-padding;
}
