/** @define Text */

@mixin Text
{
  @include font-sizes(14px, 24px);
  color: $text-primary;
}

@mixin Text--white
{
  @include font-sizes(14px, 24px);
  color: $brand-white;
}

.Text
{
  @include Text;

  &--white
  {
    color: $brand-white;
  }

  &--small
  {
    @include font-sizes(12px, 20px);
  }

  &--bold
  {
    font-weight: $font-weight-bold;
  }

  &--light
  {
    font-weight: $font-weight-light;
  }

  &--semibold
  {
    font-weight: $font-weight-semibold;
  }
}
