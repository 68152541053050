// Add any font families here

@font-face
{
  font-family: 'FIMain';
  src: url('fonts/FIMain-Regular.eot');
  src:
    url('fonts/FIMain-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/FIMain-Regular.woff') format('woff'),
    url('fonts/FIMain-Regular.ttf') format('truetype');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'FIMessage';
  src: url('fonts/FIMessageWeb-Regular.eot');
  src:
    url('fonts/FIMessageWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/FIMessageWeb-Regular.woff') format('woff'),
    url('fonts/FIMessageOffc-Regular.ttf') format('truetype');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'FIMain-Light';
  src: url('fonts/FIMain-Light.otf');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
