/** @define ResultList */

.ResultList
{
  &-item
  {
    padding: 0;
    margin: 0 $spaced-margin $spaced-margin/2;

    @include bplte(sm)
    {
      margin: 0;
    }

    @include bpgte(md)
    {
      // Most of the styling in this block is for the border
      // animations when hovering and selecting results

      &.is-selected:not(:active) .Teaser
      {
        /* postcss-bem-linter: ignore */
        &::before,
        &::after
        {
          width: 100%;
          height: 100%;
        }

        &::before
        {
          border-top-color: $brand-orange;
          border-right-color: $brand-orange;
          // Width expands first, then height
          transition:
            width 300ms ease-out,
            height 300ms ease-out 300ms;
        }

        &::after
        {
          top: 0;
          border-bottom-color: $brand-orange;
          border-left-color: $brand-orange;
          // Animate height first, then width
          transition:
            height 300ms ease-out,
            width 300ms ease-out 300ms;
        }
      }

      &:active .Teaser,
      &.is-active .Teaser
      {
        /* postcss-bem-linter: ignore */
        &::before,
        &::after
        {
          width: 0;
          height: 0;
          border: $border-blank;
          transition: none;
        }
      }

      &:hover:not(.is-selected):not(:active) .Teaser,
      &.is-hovered:not(.is-selected):not(:active) .Teaser
      {
        &::after
        {
          width: 100%;
          top: 100%;
          border-bottom-color: $brand-orange;
          // Animate width
          transition: width 300ms ease-out;
        }
      }

      &--ordered
      {
        counter-increment: resultCount;
        position: relative;

        &::before
        {
          @include Heading--low;
          content: counter(resultCount, decimal);
          position: absolute;
          left: $spaced-margin;
          top: $spaced-margin;
          width: 26px;
          height: 26px;
          border: $border-orange;
          border-radius: 13px;
          text-align: center;
          z-index: 1;
        }
      }

      &--mortgage
      {
        &:hover:not(.is-selected):not(:active) .Teaser::after,
        &.is-hovered:not(.is-selected):not(:active) .Teaser::after
        {
          border-bottom-color: $brand-blue;
        }

        &.is-selected:not(:active) .Teaser::before
        {
          border-top-color: $brand-blue;
          border-right-color: $brand-blue;
        }

        &.is-selected:not(:active) .Teaser::after
        {
          border-bottom-color: $brand-blue;
          border-left-color: $brand-blue;
        }

        &::before
        {
          border: $border-blue;
        }
      }

      &--wealth
      {
        &:hover:not(.is-selected):not(:active) .Teaser::after,
        &.is-hovered:not(.is-selected):not(:active) .Teaser::after
        {
          border-bottom-color: $brand-black;
        }

        &.is-selected:not(:active) .Teaser::before
        {
          border-top-color: $brand-black;
          border-right-color: $brand-black;
        }

        &.is-selected:not(:active) .Teaser::after
        {
          border-bottom-color: $brand-black;
          border-left-color: $brand-black;
        }

        &::before
        {
          border: $border-black;
        }
      }
    }
  }
}
