/** @define Directory */

.Directory
{
  &--ace
  {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &--ace &-row
  {
    margin-left: 0;
    margin-right: 0;
  }

  &--ace &-title
  {
    @include l-container;
    @include Heading--major;
    margin-top: $spaced-margin;
    padding-bottom: $grid-gutter-mini;
    border-bottom: $border-default;
    font-family: $font-family-tertiary;
    font-weight: 500;

    @include bplte(xs)
    {
      margin-top: $grid-gutter-mini;
    }
  }

  &--ace &-content
  {
    @include l-container;
    margin-top: $grid-gutter-mini*2;
    margin-bottom: $grid-gutter-width;

    @include bplte(xs)
    {
      margin-top: $grid-gutter-mini;
      margin-bottom: 0;
    }
  }

  &--ace &-listTeasers
  {
    border-bottom: 0;
    margin-left: -$spaced-margin;
    margin-right: -$spaced-margin;

    @include bplte(xs)
    {
      margin-bottom: $grid-gutter-mini;
    }
  }

  &--ace &-listTeaser:not(:last-child)
  {
    @include bplte(xs)
    {
      margin-bottom: $grid-gutter-mini;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &--ace &-listLink
  {
    text-transform: none;

    &:hover .Directory-listLinkText
    {
      text-decoration: none;
    }

    @include bplte(xs)
    {
      margin-bottom: $grid-gutter-mini;
    }
  }

  &--ace &-listLinkText
  {
    @include Link--body;

    font-size: 16px;
  }
}
