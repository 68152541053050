/** @define Heading */

@mixin Heading--lead
{
  @include font-sizes(75px, 83px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;

  @include bplte(xs)
  {
    @include font-sizes(34px, 40px);
  }
}

@mixin Heading--major
{
  @include font-sizes(42px, 46px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;

  @include bplte(xs)
  {
    @include font-sizes(24px, 32px);
  }
}

@mixin Heading--minor
{
  @include font-sizes(32px, 35px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;

  @include bplte(xs)
  {
    @include font-sizes(20px, 28px);
  }
}

@mixin Heading--sub
{
  @include font-sizes(18px, 20px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
}

@mixin Heading--low
{
  @include font-sizes(16px, 24px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
}

@mixin Heading--colTitle
{
  @include font-sizes(18px, 19px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
}

@mixin Heading--footer
{
  @include font-sizes(56px, 96px);
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;

  @include bplte(xs)
  {
    @include font-sizes(28px, 60px);
  }
}

.Heading
{
  &--lead
  {
    @include Heading--lead;
  }

  &--major
  {
    @include Heading--major;
  }

  &--minor
  {
    @include Heading--minor;
  }

  &--sub
  {
    @include Heading--sub;
  }

  &--low
  {
    @include Heading--low;
  }

  &--colTitle
  {
    @include Heading--colTitle;
  }

  &--footer
  {
    @include Heading--footer;
  }
}
