/** @define Header */

$header-desktop-break-point-min: sm !default;
$header-mobile-break-point-max: xs !default;

$header-backgroundColor: $brand-primary;

$header-overlayColor: transparent !default;
$header-overlayFocusShadow: inset 0 0 1.5em $brand-primary !default;
$header-overlayTransition: opacity 300ms ease !default;

$header-mobileBackgroundColor: white !default;
$header-heightTransition: max-height 300ms ease !default;
$header-mobileLinksBorder: $border-default !default;

$header-logoHeight: 38px;

$header-linkSpacingAroundMobile: 1rem !default;
$header-linkSpacingBetweenDesktop: 2rem !default;

$header-toggleBtnHeight: 25px !default;
$header-toggleBtnWidth: 25px !default;
$header-toggleBtnColor: white !default;
$header-toggleBtnBarHeight: 4px !default;
$header-toggleBtnBarSpacing: 8px !default;

.Header
{
  display: flex;
  flex-shrink: 0;
  height: $header-height-desktop;
  padding-top: 24px;
  background-color: $gray-9;

  @include bplte($header-mobile-break-point-max)
  {
    height: $header-height-mobile;
    padding: 0;
  }

  &-wrapper
  {
    display: flex;

    height: 100%;
    width: 100%;

    @include bpgte($header-desktop-break-point-min)
    {
      border-bottom: 10px solid $brand-orange;
    }

    @include bplte($header-mobile-break-point-max)
    {
      position: relative;
      padding: $padded-padding/2 0;
      z-index: 5;
    }
  }

  &-container
  {
    height: 100%;
    position: relative;
  }

  &-locations
  {
    position: relative;
    font-size: 18px;

    .Header-linkText
    {
      font-size: 18px;

      &:active
      {
        color: $brand-primary;
      }

      @include bplte(xs)
      {
        display: none;
      }
    }
  }

  &-pin
  {
    width: 19px;
    height: 19px;
    margin-top: 4px;
    display: inline-flex;
    content: url('pin.svg');

    @include bpgte(sm)
    {
      position: absolute;
      top: -7px;
      left: -7px;
    }
  }

  &-main
  {
    height: 100%;

    @include bpgte($header-desktop-break-point-min)
    {
      position: relative;
    }

    @include bplte($header-mobile-break-point-max)
    {
      display: flex;
      justify-content: space-between;
    }
  }

  &-overlay
  {
    @include bplte($header-mobile-break-point-max)
    {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      display: none;

      height: 100%;
      width: 100%;

      background-color: $header-overlayColor;

      opacity: 0;

      z-index: 4;

      transition: $header-overlayTransition;

      &:focus
      {
        box-shadow: $header-overlayFocusShadow;
      }
    }
  }

  &-content
  {
    @include bpgte($header-desktop-break-point-min)
    {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include bplte($header-mobile-break-point-max)
    {
      max-height: 0;
      border-top: $border-default;
      position: absolute;
      top: 54px;
      left: 0;
      right: 0;
      width: 100%;

      background-color: $header-mobileBackgroundColor;

      overflow: hidden;

      transition: $header-heightTransition;
    }
  }

  &-logoLink,
  &-logoWrapper
  {
    display: inline-flex;
  }

  &-logoWrapper--centered
  {
    @include bplte($header-mobile-break-point-max)
    {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-logoImage
  {
    height: $header-logoHeight;
    width: auto;
  }

  &-menu
  {
    display: flex;

    @include bplte($header-mobile-break-point-max)
    {
      flex-direction: column;
    }

    &--desktop
    {
      @include bplte($header-mobile-break-point-max)
      {
        display: none;
      }
    }

    &--mobile
    {
      border-bottom: $border-orange;

      @include bpgte($header-desktop-break-point-min)
      {
        display: none;
      }
    }
  }

  &-menuMain
  {
    display: flex;
    justify-content: space-between;

    @include bpgte($header-desktop-break-point-min)
    {
      margin-top: $grid-gutter-mini;
    }

    @include bplte($header-mobile-break-point-max)
    {
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  &-topMenuItem
  {
    display: flex;

    @include bplte($header-mobile-break-point-max)
    {
      flex-direction: column;
    }

    /* postcss-bem-linter: ignore */
    &:last-child .Header-dropDownMobile--isOpen
    {
      border-bottom: none;
    }

    /* postcss-bem-linter: ignore */
    &.l-hidden
    {
      display: none;
    }
  }

  &-mainButton
  {
    border-bottom: 5px solid transparent;
    font-weight: $font-weight-semibold;

    &:first-child
    {
      padding-left: 0;
      padding-right: 30px;
    }

    &:focus,
    &:active,
    &:hover
    {
      color: $brand-cream;
      border-color: transparent;
    }
  }

  &-mainButton--isOpen,
  &-mainButton--isOpen:hover
  {
    border-bottom-color: $brand-cream;
  }

  &-mainButtonMobile,
  &-mainButtonMobile--locator
  {
    padding: $padded-padding;
    text-align: left;
    border-bottom: $border-default;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* postcss-bem-linter: ignore */
    &:focus
    {
      outline: 0;
    }

    /* postcss-bem-linter: ignore */
    &:hover
    {
      background-color: $gray-5;
    }
  }

  &-mainButtonMobile
  {
    &--isOpen
    {
      /* postcss-bem-linter: ignore */
      .Header-chevron
      {
        transform: rotate(90deg);
      }
    }
  }

  &-login
  {
    @include font-sizes(18px, 24px);
    padding: 4px 20px;
    display: inline-flex;
    align-items: center;
    background-color: white;
    color: $gray-9;
    transition: background-color 300ms ease;

    &:hover
    {
      background-color: $brand-orange;
      color: white;
    }

    @include bplte($header-mobile-break-point-max)
    {
      margin-right: $spaced-margin;
      background-color: transparent;
    }
  }

  &-loginText
  {
    @include bplte(xs)
    {
      display: none;
    }
  }

  &-menuItem
  {
    display: flex;

    &:not(:last-child)
    {
      @include bplte($header-mobile-break-point-max)
      {
        border-bottom: $header-mobileLinksBorder;
      }

      @include bpgte($header-desktop-break-point-min)
      {
        padding-right: $header-linkSpacingBetweenDesktop;
      }
    }
  }

  &-dropDowns
  {
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    top: $header-height-desktop;
    left: 0;
    height: 0;
    max-height: 0;
    width: 100%;
    background-color: $brand-cream;
    border-bottom: $border-cream;
    transition: max-height 300ms ease;
    color: white;
    margin-top: -10px;

    &--isOpen
    {
      visibility: visible;
      height: auto;
      max-height: 700px;
      z-index: 3;
    }
  }

  &-dropDown
  {
    display: none;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: $font-family-main;

    &--isOpen
    {
      @include bpgte($header-desktop-break-point-min)
      {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &-col
  {
    @include l-col(1, 4);
    display: flex;
    flex-direction: column;
    float: left;

    @include bplte(sm)
    {
      @include l-col(1, 3);
    }
  }

  &-colTitle
  {
    @include font-sizes(14px, 20px);

    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $gray-9;
    margin: 13px 0 11px;
  }

  &-subLink
  {
    padding: 2px 0;

    &:hover
    {
      /* postcss-bem-linter: ignore */
      .Header-subLinkText
      {
        left: 5px;
      }
    }

    &--nested
    {
      /* postcss-bem-linter: ignore */
      .Header-subLinkText
      {
        padding-left: 20px;
      }
    }

    &--mobile
    {
      padding: $padded-padding;

      /* postcss-bem-linter: ignore */
      &:hover
      {
        background-color: $gray-5;
      }

      /* postcss-bem-linter: ignore */
      &:focus
      {
        outline: 0;
      }
    }
  }

  &-chevron
  {
    transition: transform 300ms ease;
    transform: rotate(0deg);
    height: 14px;

    @include bpgte($header-desktop-break-point-min)
    {
      display: none;
    }
  }

  &-subLinkText
  {
    @include font-sizes(16px, 18px);

    color: $gray-9;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid transparent;
    position: relative;
    left: 0;
    transition: left 300ms ease;
  }

  &-dropDownMobile
  {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    height: 0;
    max-height: 0;
    transition: max-height 300ms ease;

    &--isOpen
    {
      @include bplte($header-mobile-break-point-max)
      {
        visibility: visible;
        height: auto;
        max-height: 800px;
        border-bottom: $border-orange;
      }
    }
  }

  &-innerDrop
  {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    height: 0;
    max-height: 0;
    transition: max-height 300ms ease;

    &--isOpen
    {
      @include bplte($header-mobile-break-point-max)
      {
        visibility: visible;
        height: auto;
        max-height: 700px;
      }

      &:not(:last-child)
      {
        border-bottom: $border-orange;
      }
    }
  }

  &-innerSubButton
  {
    padding: $padded-padding;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child)
    {
      border-bottom: $border-default;
    }

    /* postcss-bem-linter: ignore */
    &:focus
    {
      outline: 0;
    }

    /* postcss-bem-linter: ignore */
    &:hover
    {
      background-color: $gray-5;
    }

    &--isOpen
    {
      /* postcss-bem-linter: ignore */
      .Header-chevron
      {
        transform: rotate(90deg);
      }
    }
  }

  &-link
  {
    @include bplte($header-mobile-break-point-max)
    {
      width: 100%;
      padding: $header-linkSpacingAroundMobile;
    }
  }

  &-toggle
  {
    @include bplte($header-mobile-break-point-max)
    {
      display: flex;
    }
  }

  &-lockIcon
  {
    height: 19px;
    width: 19px;

    @include bpgte(sm)
    {
      display: none;
    }
  }

  &-toggleIcon
  {
    @include bplte($header-mobile-break-point-max)
    {
      position: relative;
      top: $header-toggleBtnBarHeight;

      height: $header-toggleBtnHeight;
      width: $header-toggleBtnWidth;
    }
  }

  &-menuBar
  {
    display: block;

    position: absolute;
    left: 0;

    height: $header-toggleBtnBarHeight;
    width: 100%;

    background: $header-toggleBtnColor;

    border-radius: 0;

    opacity: 1;

    transform: rotate(0deg);
    transition: 300ms ease-in-out;

    &:nth-child(1)
    {
      top: 0 * $header-toggleBtnBarSpacing;
    }

    &:nth-child(2),
    &:nth-child(3)
    {
      top: 1 * $header-toggleBtnBarSpacing;
    }

    &:nth-child(4)
    {
      top: 2 * $header-toggleBtnBarSpacing;
    }
  }

  &.isOpen
  {
    .Header-menuBar
    {
      /* postcss-bem-linter: ignore */
      &:nth-child(1),
      &:nth-child(4)
      {
        top: 1 * $header-toggleBtnBarSpacing;
        width: 0;
        left: 50%;
      }

      /* postcss-bem-linter: ignore */
      &:nth-child(2)
      {
        transform: rotate(45deg);
      }

      /* postcss-bem-linter: ignore */
      &:nth-child(3)
      {
        transform: rotate(-45deg);
      }
    }
  }
}
