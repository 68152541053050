/** @define Button */

$button-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.5);

@mixin Button
{
  @include font-sizes(18px, 26px);
  font-weight: $font-weight-normal;
  color: $text-primary;
  background-color: $brand-white;
  border: solid 2px $brand-orange;
  padding: 10px $padded-padding;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include bplte(xs)
  {
    width: 100%;
  }

  /* postcss-bem-linter: ignore */
  &:hover
  {
    border-color: $brand-red;
    cursor: pointer;
  }

  /* postcss-bem-linter: ignore */
  &:active
  {
    box-shadow: $button-shadow;
  }

  /* postcss-bem-linter: ignore */
  &:link,
  &:visited,
  &:active,
  &:hover,
  &:focus
  {
    text-decoration: none;
    color: $text-primary;
  }
}

@mixin Button-primary
{
  @include Button;
  transition: all 0.3s ease;
  border: none;
  background-color: $brand-orange-ada;

  /* postcss-bem-linter: ignore */
  &:link,
  &:visited
  {
    color: $brand-white;
    font-weight: $font-weight-bold;
  }

  @include fa-icon-after($fa-var-angle-right)
  {
    margin-left: 5px;
  }

  /* postcss-bem-linter: ignore */
  &:hover
  {
    transition: all 0.3s ease;
    background-color: $brand-hover;
    cursor: pointer;
  }
}

@mixin Button--secondary
{
  @include Button;
  background-color: $brand-white;
  border: solid 1px $gray-7;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    cursor: pointer;
  }

  /* postcss-bem-linter: ignore */
  &:hover,
  &:active,
  &:focus
  {
    background-color: $gray-5;
    border-color: $gray-6;
  }
}

.Button
{
  @include Button;

  &--secondary
  {
    @include Button--secondary;
  }
}
